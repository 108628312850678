<template>
  <div>
		<b-overlay
			:show="overlayLogCorporate"
			spinner-variant="primary"
			spinner-type="grow"
			spinner-small
			rounded="sm"
		>
			<b-card-code
				title="Log Activity Corporate"
				no-body
				:aria-hidden="overlayLogCorporate ? true : null"
			>
				<b-table
					striped
					hover
					responsive
					class="position-relative"
					:per-page="perPage"
					:current-page="currentPage"
					:items="items"
					:fields="fields"
				>
					<template #cell(created_at)="data">
						{{ data.item.created_at | dateFilter }}
					</template>

					<template #cell(total_member)="data">
						<b-progress
							v-b-tooltip.hover.top="data.item.member_text"
							:max="data.item.target_member"
							class="progress-bar-info"
						>
							<b-progress-bar
								:value="data.item.total_member"
								variant="info"
							/>
						</b-progress>
					</template>
				</b-table>

				<b-card-body class="d-flex justify-content-between flex-wrap pt-0">
					<!-- page length -->
					<b-form-group
						label="Per Page"
						label-cols="6"
						label-align="left"
						label-size="sm"
						label-for="sortBySelect"
						class="text-nowrap mb-md-0 mr-1"
					>
						<b-form-select
							id="perPageSelect"
							v-model="perPage"
							size="sm"
							inline
							:options="pageOptions"
						/>
					</b-form-group>

					<!-- pagination -->
					<div>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							first-number
							last-number
							prev-class="prev-item"
							next-class="next-item"
							class="mb-0"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</div>
				</b-card-body>
			</b-card-code>
	</b-overlay>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
	import {
		BTable, BAvatar, BBadge, BCardBody, VBModal, VBTooltip,
		BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, 
		BDropdown, BDropdownItem, BOverlay,
		BProgress, BProgressBar, BCol, BRow
	} from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
	import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
  import moment from 'moment';
  
  export default {
	components: {
		BCardCode,
		BTable,
		BAvatar,
		BBadge,
		BFormGroup,
		BFormSelect,
		BPagination,
		BInputGroup,
		BFormInput,
		BInputGroupAppend,
		BButton,
		BCardBody,
		BDropdown, BDropdownItem, BOverlay,
		BProgress, BProgressBar, BCol, BRow,
		StatisticCardHorizontal
	},
	directives: {
		Ripple,
		'b-tooltip': VBTooltip,
	},
	data() {
	  return {
			perPage: 50,
			pageOptions: [10, 50, 100],
			currentPage: 1,
			sortBy: '',
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
			filterOn: [],
			infoModal: {
				id: 'info-modal', 
				title: '',
				content: '',
			},
      data: [],
			fields: [
				{ key: 'user.member.member_first_name', label: 'Name' },
				{ key: 'description', label: 'Description' },
				{ key: 'created_at', label: 'Date' }
			],
			overlayLogCorporate: true,
			active_role: null
	  }
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter(f => f.sortable)
				.map(f => ({ text: f.label, value: f.key }))
		},
		items() {
			return this.data
		},
		totalRows() {
			return this.data.length
		}
	},
	created() {
	  this.checkAuth()
	},
	mounted() {
	  this.active_role = localStorage.getItem('role')
		this.getDataLog()
	},
	filters: {
    dateFilter: function (value) {
      return moment(String(value)).format('DD-MM-YYYY hh:mm') 
    }
	},
	methods: {
	  async getDataLog() {
      let config = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }
			this.$axios.get('/mjVmBSJ31Hx2Ag9GCOUS/member/corporate/log-activity', config)
		  	.then(res => {
          if (res.data.status === 'success') {
            this.overlayLogCorporate = false
            console.log(res.data)
            this.data = res.data.data
          }
		  	})
				.catch(error => {})
		},
	}
}
</script>